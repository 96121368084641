


























































































import { useUiState } from '~/composables';
import {
  defineComponent, computed, onMounted, useContext, ref, useRouter,
} from '@nuxtjs/composition-api';
import {
  SfTopBar, SfButton, SfLink, SfOverlay, SfBadge,
} from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import HeaderLogoVector from '~/components/HeaderLogoVector.vue';
import AccountDropdown from '~/components/TopBar/AccountDropdown.vue';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { useAlgoliaStore } from '~/modules/algolia/stores/algolia';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  components: {
    SfTopBar,
    SfButton,
    SfLink,
    SfOverlay,
    SfBadge,
    SvgImage,
    HeaderLogoVector,
    AccountDropdown,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchSuggestions: () => import(
      /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchSuggestions.vue'
    ),
  },
  setup() {
    const { app } = useContext();
    const router = useRouter();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleCovModal } = useUiState();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { algoliaEnabled } = useAlgoliaStore();
    const { isAuthenticated } = useUser();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const searchQuery = ref<string | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    const handleWishlistClick = async () => {
      await router.push(app.localeRoute({ name: isAuthenticated.value ? 'customer-my-wishlist' : 'guest-wishlist' }));
    };

    return {
      toggleCartSidebar,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      toggleWishlistSidebar,
      toggleCovModal,
      handleWishlistClick,
      wishlistHasProducts,
      wishlistItemsQty,
      isSearchOpen,
      productSearchResults,
      searchQuery,
      algoliaEnabled,
    };
  },
});

