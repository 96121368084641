const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    is_anchor
    level
    name
    description
    meta_description
    image
    additional_home_image
    short_description
    image2
    image2_description
    image3
    image3_description
    image4
    image4_description
    image5
    image5_description
    position
    product_count
    uid
    url_path
    url_suffix
    include_in_menu
  }
`;

export default `
  query categoryList($filters: CategoryFilterInput) {
    categories(filters: $filters) {
      items {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
            children {
              ...CategoryFields
            }
          }
        }
      }
    }
  }
  ${fragmentCategory}
`;
